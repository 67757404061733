@if $decoratorsEnabled {
    @each $decorator, $declaration in $decorators {
        @each $property, $value in $declaration {
            [#{$decoratorsAttribute}~="#{$decorator}"] {
                #{$property}: $value;
            }
        }
    }
    @if $decoratorsBreakpoints {
        @each $breakpoint, $resolution in $breakpoints {
            @media (max-width: $resolution) {
                @each $decorator, $declaration in $decorators {
                    @each $property, $value in $declaration {
                        [#{$decoratorsAttribute}~="@#{$breakpoint}=#{$decorator}"] {
                            #{$property}: $value;
                        }
                    }
                }
            }
        }
    }
}



@keyframes pulse_1__1 {
	100% {
		transform: scale(2);
        opacity: 0;
	}
}

@keyframes pulse_2__1 {
	50% {
		transform: scale(1.4);
	}
    100% {
		transform: scale(1);
	}
}

@keyframes marquee_1__1 {
    to {
        transform: translateX(-110%)
    }
}

@keyframes scroll_1__1 {
	100% {
		top: 35px;
		opacity: 0;
	}
}

@keyframes rotate_1__1 {
	100% {
		rotate: 360deg;
	}
}



@mixin a($e) { @extend [data-a~="#{$e}"]; }
@mixin d($e) { @extend [data-d~="#{$e}"]; }
@mixin g($e) { @extend [data-g~="#{$e}"]; }



$color1st: #b50315;
$color2nd: #20313b;
$color3rd: #f2f2f2;
$color4th: #cccccc;
$color5th: black;
$color6th: black;
$color7th: #f1f1f1;
$color-we: white;
$main-1: #4c5561;
$color3: #cccccc;
$color21: #0082fa;
$color22: #ebebeb;
$color23: #6e6e6e;
$color24: rgba(255, 255, 255, .4);
$color25: #f4f4f1;
$color26: rgba(0, 0, 0, .15);





[data-d~="se=we"] { stroke: white }
[data-d~="se=bk"] { stroke: black }

[data-d~="fl=we"] { fill: white }
[data-d~="fl=bk"] { fill: black }
[data-d~="fl=gy"] { fill: gray }
[data-d~="fl=bk"] { fill: #c00418 }



[data-d~="fr=br(5px)"] { filter: blur(5px) }



[data-d~="bd=1"] { background: linear-gradient(180deg, $color3rd 0%, $color3rd 50%, $color-we 100%) }
[data-d~="bd=2"] { background: linear-gradient(180deg, $color3rd 0%, $color-we 20%, $color-we 100%) }
[data-d~="bd=3"] {background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(253,253,253,1) 50%, rgba(229,229,229,1) 100%); }

[data-d~="bd-cr=tt"] { background-color: transparent }
[data-d~="bd-cr=we"] { background-color: white }
[data-d~="bd-cr=bk"] { background-color: black }

[data-d~="bd-cr=1st"] { background-color: $color1st }
[data-d~="bd-cr=2nd"] { background-color: $color2nd }
[data-d~="bd-cr=3rd"] { background-color: $color3rd }
[data-d~="bd-cr=4th"] { background-color: $color4th }
[data-d~="bd-cr=5th"] { background-color: $color5th }
[data-d~="bd-cr=6th"] { background-color: $color6th } 
[data-d~="bd-cr=7th"] { background-color: $color7th } 
[data-d~="bd-cr=3"] { background-color: $color3 }
[data-d~="bd-cr=23"] { background-color: $color23 }
[data-d~="bd-cr=main-1"] { background-color: $main-1 }

[data-d~="bd-cr=_1"] { background-color: #cccccc }
[data-d~="bd-cr=_2"] { background-color: #ededed }
[data-d~="bd-cr=_3"] { background-color: rgba(0, 0, 0, .8) }
[data-d~="bd-cr=_4"] { background-color: #f2f2f2 }
[data-d~="bd-cr=_5"] { background-color: #edece7 }
[data-d~="bd-cr=_6"] { background-color: $color24 }
[data-d~="bd-cr=_7"] { background-color: $color25 }
[data-d~="bd-cr=_8"] { background-color: $color26 }
[data-d~="bd-cr=_9"] { background-color: #BB2032 }
[data-d~="bd-cr=_10"] { background-color: #575757 }



[data-d~="bx-sw=1"] { box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .2) }
[data-d~="bx-sw=2"] { box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, .08) }



[data-d~="gd-te=1"] { grid-template: min-content 1fr min-content / 100% }



[data-d~="bd-rt=no-rt"] { background-repeat: no-repeat }

[data-d~="bd-se=cr"] { background-size: cover }

[data-d~="bd-pn=cr"] { background-position: center }



[data-d~="br-se=sd"] { border-style: solid }
[data-d~="br-se=dd"] { border-style: dashed }

[data-d~="br-tp-se=sd"] { border-top-style: solid }

[data-d~="br-rt-se=sd"] { border-right-style: solid }

[data-d~="br-bm-se=sd"] { border-bottom-style: solid }

[data-d~="br-lt-se=sd"] { border-left-style: solid }
[data-d~=":nt(:ft-cd)=br-lt-se=sd"]:not(:first-child) { border-left-style: solid }

[data-d~="br-ce=ce"] { border-collapse: collapse }





[data-d~="br-cr=we"] { border-color: white }
[data-d~="br-cr=bk"] { border-color: black }

[data-d~="br-cr=1st"] { border-color: $color1st }
[data-d~="br-cr=2nd"] { border-color: $color2nd }
[data-d~="br-cr=3rd"] { border-color: $color3rd }
[data-d~="br-cr=4th"] { border-color: $color4th }
[data-d~="br-cr=5th"] { border-color: $color5th }
[data-d~="br-cr=6th"] { border-color: $color6th }
[data-d~="br-cr=3"] { border-color: $color3 }

[data-d~="br-cr=_1"] { border-color: rgba(255, 255, 255, .2) }
[data-d~="br-cr=_2"] { border-color: #ebebeb }
[data-d~="br-cr=_3"] { border-color: #cccccc }

[data-d~="br-wh=1px"] { border-width: 1px }
[data-d~="br-wh=2px"] { border-width: 2px }
[data-d~="br-wh=3px"] { border-width: 3px }
[data-d~="br-wh=6px"] { border-width: 6px }

[data-d~="br-rs=100%"] { border-radius: 100% }
[data-d~="br-rs=3px"] { border-radius: 3px }
[data-d~="br-rs=4px"] { border-radius: 4px }
[data-d~="br-rs=6px"] { border-radius: 6px }
[data-d~="br-rs=10px"] { border-radius: 10px }
[data-d~="br-rs=20px"] { border-radius: 20px }



[data-d~="at-ro=30"] { aspect-ratio: 100 / 30 }
[data-d~="at-ro=40"] { aspect-ratio: 100 / 40 }
[data-d~="at-ro=60"] { aspect-ratio: 100 / 60 }
[data-d~="at-ro=70"] { aspect-ratio: 100 / 70 }
[data-d~="at-ro=100"] { aspect-ratio: 100 / 100 }



[data-d~="wh=0"] { width: 0 }
[data-d~="wh=1px"] { width: 1px }
[data-d~="wh=2px"] { width: 2px }
[data-d~="wh=4px"] { width: 4px }
[data-d~="wh=6px"] { width: 6px }
[data-d~="wh=8px"] { width: 8px }
[data-d~="wh=10px"] { width: 10px }
[data-d~="wh=12px"] { width: 12px }
[data-d~="wh=15px"] { width: 15px }
[data-d~="wh=20px"] { width: 20px }
[data-d~="wh=25px"] { width: 25px }
[data-d~="wh=30px"] { width: 30px }
[data-d~="wh=35px"] { width: 35px }
[data-d~="wh=40px"] { width: 40px }
[data-d~="wh=45px"] { width: 45px }
[data-d~="wh=50px"] { width: 50px }
[data-d~="wh=60px"] { width: 60px }
[data-d~="wh=70px"] { width: 70px }
[data-d~="wh=100px"] { width: 100px }
[data-d~="wh=130px"] { width: 130px }
[data-d~="wh=150px"] { width: 150px }
[data-d~="wh=180px"] { width: 180px }
[data-d~="wh=200px"] { width: 200px }
[data-d~="wh=280px"] { width: 280px }
[data-d~="wh=300px"] { width: 300px }
[data-d~="wh=400px"] { width: 400px }
[data-d~="wh=492px"] { width: 492px }
[data-d~="wh=500px"] { width: 500px }
[data-d~="wh=600px"] { width: 600px }
[data-d~="wh=900px"] { width: 900px }
[data-d~="wh=30%"] { width: 30% }
[data-d~="wh=cc(1)"] { width: calc(100% - 100px) }

[data-d~="mn-wh=35px"] { min-width: 35px }
[data-d~="mn-wh=50px"] { min-width: 50px }
[data-d~="mn-wh=100px"] { min-width: 100px }
[data-d~="mn-wh=120px"] { min-width: 120px }

[data-d~="mx-wh=100%"] { max-width: 100% }
[data-d~="mx-wh=197px"] { max-width: 197px }
[data-d~="mx-wh=200px"] { max-width: 200px }
[data-d~="mx-wh=300px"] { max-width: 300px }
[data-d~="mx-wh=360px"] { max-width: 360px }


[data-d~="ht=ao"] { height: auto }
[data-d~="ht=0"] { height: 0 }
[data-d~="ht=1px"] { height: 1px }
[data-d~="ht=4px"] { height: 4px }
[data-d~="ht=8px"] { height: 8px }
[data-d~="ht=10px"] { height: 10px }
[data-d~="ht=15px"] { height: 15px }
[data-d~="ht=20px"] { height: 20px }
[data-d~="ht=25px"] { height: 25px }
[data-d~="ht=30px"] { height: 30px }
[data-d~="ht=35px"] { height: 35px }
[data-d~="ht=40px"] { height: 40px }
[data-d~="ht=50px"] { height: 50px }
[data-d~="ht=56px"] { height: 56px }
[data-d~="ht=60px"] { height: 60px }
[data-d~="ht=100px"] { height: 100px }
[data-d~="ht=180px"] { height: 180px }
[data-d~="ht=15vw"] { height: 15vw }
[data-d~="ht=30vw"] { height: 30vw }
[data-d~="ht=30%"] { height: 30% }
[data-d~="ht=60%"] { height: 60% }
[data-d~="ht=70%"] { height: 70% }
[data-d~="ht=90%"] { height: 90% }
[data-d~="ht=100%"] { height: 100% }
[data-d~="ht=220px"] { height: 220px }
[data-d~="ht=250px"] { height: 250px }
[data-d~="ht=260px"] { height: 260px }


[data-d~="mn-ht=25px"] { min-height: 25px }
[data-d~="mn-ht=30px"] { min-height: 30px }
[data-d~="mn-ht=35px"] { min-height: 35px }
[data-d~="mn-ht=40px"] { min-height: 40px }
[data-d~="mn-ht=50px"] { min-height: 50px }
[data-d~="mn-ht=150px"] { min-height: 150px }
[data-d~="mn-ht=200px"] { min-height: 200px }
[data-d~="mn-ht=500px"] { min-height: 500px }
[data-d~="mn-ht=100%"] { min-height: 100% }

[data-d~="mx-ht=150px"] { max-height: 150px }
[data-d~="mx-ht=348px"] { max-height: 348px }



[data-d~="ft-fy=default"] { font-family: "WorkSans-Regular" }
[data-d~="ft-fy=1st-rr"] { font-family: "WorkSans-Regular" }
[data-d~="ft-fy=1st-bd"] { font-family: "WorkSans-Bold" }
[data-d~="ft-fy=2nd-sibd"] { font-family: "WorkSans-Bold" }



[data-d~="ft-fy=main-rr"] { font-family: "WorkSans-Regular" }
[data-d~="ft-fy=main-bd"] { font-family: "WorkSans-Bold" }

[data-d~="ft-fy=1-rr"] { font-family: "WorkSans-Regular" }
[data-d~="ft-fy=1-bd"] { font-family: "WorkSans-Bold" }

[data-d~="ft-fy=2-rr"] { font-family: "Blinker-Regular" }



[data-d~="co=we"] { color: white }
[data-d~="co=bk"] { color: black }
[data-d~="co=rd"] { color: red }

[data-d~="co=1st"] { color: $color1st }
[data-d~="co=2nd"] { color: $color2nd }
[data-d~="co=3rd"] { color: $color3rd }
[data-d~="co=4th"] { color: $color4th }
[data-d~="co=5th"] { color: $color5th }
[data-d~="co=6th"] { color: $color6th }

[data-d~="co=_1"] { color: #575757 }

[data-d~="ft-se=1px"] { font-size: 1px }
[data-d~="ft-se=11rem"] { font-size: 11rem }
[data-d~="ft-se=12rem"] { font-size: 12rem }
[data-d~="ft-se=13rem"] { font-size: 13rem }
[data-d~="ft-se=14rem"] { font-size: 14rem }
[data-d~="ft-se=16rem"] { font-size: 16rem }
[data-d~="ft-se=18rem"] { font-size: 18rem }
[data-d~="ft-se=20rem"] { font-size: 20rem }
[data-d~="ft-se=22rem"] { font-size: 22rem }
[data-d~="ft-se=24rem"] { font-size: 24rem }
[data-d~="ft-se=28rem"] { font-size: 28rem }
[data-d~="ft-se=30rem"] { font-size: 30rem }
[data-d~="ft-se=40rem"] { font-size: 40rem }
[data-d~="ft-se=50rem"] { font-size: 50rem }
[data-d~="ft-se=60rem"] { font-size: 60rem }

[data-d~="lr-sg=il"] { letter-spacing: initial }
[data-d~="lr-sg=1px"] { letter-spacing: 1px }
[data-d~="lr-sg=2px"] { letter-spacing: 2px }
[data-d~="lr-sg=3px"] { letter-spacing: 3px }

[data-d~="lr-sg=1rem"] { letter-spacing: 1rem }

[data-d~="le-ht=.8"] { line-height: .8 }
[data-d~="le-ht=1"] { line-height: 1 }
[data-d~="le-ht=1.2"] { line-height: 1.2 }
[data-d~="le-ht=1.5"] { line-height: 1.5 }

[data-d~="tt-an=lt"] { text-align: left }
[data-d~="tt-an=rt"] { text-align: right }
[data-d~="tt-an=cr"] { text-align: center }

[data-d~="ft-wt=nl"] { font-weight: normal }
[data-d~="ft-wt=bd"] { font-weight: bold }

[data-d~="tt-tm=ue"] { text-transform: uppercase }

[data-d~="wd-bk=bk-all"] { word-break: break-all }
[data-d~="wd-bk=kp-all"] { word-break: keep-all }

[data-d~="we-se=np"] { white-space: nowrap }





[data-d~="pn=re"] { position: relative }
[data-d~="pn=ae"] { position: absolute }
[data-d~="pn=fd"] { position: fixed }
[data-d~="pn=sy"] { position: sticky }

[data-d~="tp=0"] { top: 0 }
[data-d~="tp=-15px"] { top: -15px }
[data-d~="tp=-10px"] { top: -10px }
[data-d~="tp=20px"] { top: 20px }
[data-d~="tp=40px"] { top: 40px }
[data-d~="tp=50px"] { top: 50px }
[data-d~="tp=70px"] { top: 70px }
[data-d~="tp=95px"] { top: 95px }
[data-d~="tp=135px"] { top: 135px }
[data-d~="tp=170px"] { top: 170px }

[data-d~="rt=30%"] { right: 30% }

[data-d~="rt=-10px"] { right: -10px }
[data-d~="rt=-50px"] { right: -50px }
[data-d~="rt=0"] { right: 0 }
[data-d~="rt=20px"] { right: 20px }
[data-d~="rt=60px"] { right: 60px }
[data-d~="rt=70px"] { right: 70px }
[data-d~="rt=cc(1)"] { right: calc(100% + 10px) }
[data-d~="rt=cc(2)"] { right: calc(25% + 10px) }

[data-d~="bm=0"] { bottom: 0 }
[data-d~="bm=20px"] { bottom: 20px }
[data-d~="bm=50px"] { bottom: 50px }

[data-d~="lt=0"] { left: 0 }
[data-d~="lt=20px"] { left: 20px }



[data-d~="lt-se-te=ne"] { list-style-type: none }
[data-d~="lt-se-te=dc"] { list-style-type: disc }
[data-d~="lt-se-te=dl"] { list-style-type: decimal }

[data-d~="ow=hn"] { overflow: hidden }

[data-d~="ow-x=ao"] { overflow-x: auto }
[data-d~="ow-y=ao"] { overflow-y: auto }

[data-d~="br=ne"] { border: none }

[data-d~="oe=ne"] { outline: none }

[data-d~="cu=pr"] { cursor: pointer }

[data-d~="ot-ft=cr"] { object-fit: cover }

[data-d~="dy=ne"] { display: none }

[data-d~="oy=0"] { opacity: 0 }

[data-d~="pr-es=ne"] { pointer-events: none }
[data-d~="pr-es=all"] { pointer-events: all }


[data-d~="z-ix=cookies"] { z-index: 1000 }
[data-d~="z-ix=100"] { z-index: 100 }
[data-d~="z-ix=200"] { z-index: 200 }
[data-d~="z-ix=300"] { z-index: 300 }
[data-d~="z-ix=1000"] { z-index: 1000 }
[data-d~="z-ix=modal"] { z-index: 10000 }

[data-d~="ie-rg=-wt-oe-ct"] { image-rendering: -webkit-optimize-contrast }



[data-d~="pg=1px"] { padding: 1px }
[data-d~="pg=2px"] { padding: 2px }
[data-d~="pg=4px"] { padding: 4px }
[data-d~="pg=5px"] { padding: 5px }
[data-d~="pg=10px"] { padding: 10px }
[data-d~="pg=15px"] { padding: 15px }
[data-d~="pg=20px"] { padding: 20px }
[data-d~="pg=30px"] { padding: 30px }
[data-d~="pg=40px"] { padding: 40px }
[data-d~="pg=05px"] { padding: 0 5px }
[data-d~="pg=010px"] { padding: 0 10px }
[data-d~="pg=15px20px"] { padding: 15px 20px }
[data-d~="pg=5px10px"] { padding: 5px 10px }
[data-d~="pg=5px20px"] { padding: 5px 20px }
[data-d~="pg=15px10px"] { padding: 15px 10px }
[data-d~="pg=20px10px"] { padding: 20px 10px }
[data-d~="pg=10px30px"] { padding: 10px 30px }
[data-d~="pg=10px40px"] { padding: 10px 40px }
[data-d~="pg=30px10px"] { padding: 30px 10px }
[data-d~="pg=40px10px"] { padding: 40px 10px }


[data-d~="pg-tp=il"] { padding-top: initial }
[data-d~="pg-tp=4px"] { padding-top: 4px }
[data-d~="pg-tp=5px"] { padding-top: 5px }
[data-d~="pg-tp=10px"] { padding-top: 10px }
[data-d~="pg-tp=20px"] { padding-top: 20px }
[data-d~="pg-tp=30px"] { padding-top: 30px }
[data-d~="pg-tp=40px"] { padding-top: 40px }
[data-d~="pg-tp=50px"] { padding-top: 50px }
[data-d~="pg-tp=75px"] { padding-top: 75px }
[data-d~="pg-tp=100px"] { padding-top: 100px }
[data-d~="pg-tp=100px"] { padding-top: 100px }
[data-d~="pg-tp=60%"] { padding-top: 60% }

[data-d~="pg-rt=5px"] { padding-right: 5px }
[data-d~="pg-rt=4px"] { padding-right: 4px }
[data-d~="pg-rt=10px"] { padding-right: 10px }
[data-d~="pg-rt=20px"] { padding-right: 20px }
[data-d~="pg-rt=25px"] { padding-right: 25px }
[data-d~="pg-rt=30px"] { padding-right: 30px }
[data-d~="pg-rt=40px"] { padding-right: 40px }

[data-d~="pg-bm=il"] { padding-bottom: initial }
[data-d~="pg-bm=4px"] { padding-bottom: 4px }
[data-d~="pg-bm=5px"] { padding-bottom: 5px }
[data-d~="pg-bm=9px"] { padding-bottom: 9px }
[data-d~="pg-bm=10px"] { padding-bottom: 10px }
[data-d~="pg-bm=20px"] { padding-bottom: 20px }
[data-d~="pg-bm=30px"] { padding-bottom: 30px }
[data-d~="pg-bm=40px"] { padding-bottom: 40px }
[data-d~="pg-bm=50px"] { padding-bottom: 50px }

[data-d~="pg-lt=il"] { padding-left: initial }
[data-d~="pg-lt=5px"] { padding-left: 5px }
[data-d~="pg-lt=10px"] { padding-left: 10px }
[data-d~="pg-lt=15px"] { padding-left: 15px }
[data-d~="pg-lt=20px"] { padding-left: 20px }
[data-d~="pg-lt=25px"] { padding-left: 25px }
[data-d~="pg-lt=30px"] { padding-left: 30px }
[data-d~="pg-lt=40px"] { padding-left: 40px }



[data-d~="mn=0ao"] { margin: 0 auto }
[data-d~="mn=10px"] { margin: 10px }
[data-d~="mn=30px"] { margin: 30px }

[data-d~="mn-tp=-30px"] { margin-top: -30px }
[data-d~="mn-tp=1px"] { margin-top: 1px }
[data-d~="mn-tp=2px"] { margin-top: 2px }
[data-d~="mn-tp=4px"] { margin-top: 4px }
[data-d~="mn-tp=5px"] { margin-top: 5px }
[data-d~="mn-tp=6px"] { margin-top: 6px }
[data-d~="mn-tp=10px"] { margin-top: 10px }
[data-d~="mn-tp=15px"] { margin-top: 15px }
[data-d~="mn-tp=20px"] { margin-top: 20px }
[data-d~="mn-tp=30px"] { margin-top: 30px }
[data-d~="mn-tp=35px"] { margin-top: 35px }
[data-d~="mn-tp=40px"] { margin-top: 40px }
[data-d~="mn-tp=50px"] { margin-top: 50px }
[data-d~="mn-tp=60px"] { margin-top: 60px }

[data-d~="mn-rt=-5px"] { margin-right: -5px }
[data-d~="mn-rt=-10px"] { margin-right: -10px }
[data-d~="mn-rt=6px"] { margin-right: 6px }
[data-d~="mn-rt=8px"] { margin-right: 8px }
[data-d~="mn-rt=10px"] { margin-right: 10px }
[data-d~="mn-rt=20px"] { margin-right: 20px }
[data-d~="mn-rt=30px"] { margin-right: 30px }

[data-d~="mn-bm=-30px"] { margin-bottom: -30px }
[data-d~="mn-bm=1px"] { margin-bottom: 1px }
[data-d~="mn-bm=2px"] { margin-bottom: 2px }
[data-d~="mn-bm=3px"] { margin-bottom: 3px }
[data-d~="mn-bm=4px"] { margin-bottom: 4px }
[data-d~="mn-bm=5px"] { margin-bottom: 5px }
[data-d~="mn-bm=10px"] { margin-bottom: 10px }
[data-d~="mn-bm=20px"] { margin-bottom: 20px }
[data-d~="mn-bm=30px"] { margin-bottom: 30px }
[data-d~="mn-bm=40px"] { margin-bottom: 40px }
[data-d~="mn-bm=50px"] { margin-bottom: 50px }

[data-d~="mn-lt=4px"] { margin-left: 4px }
[data-d~="mn-lt=5px"] { margin-left: 5px }
[data-d~="mn-lt=8px"] { margin-left: 8px }
[data-d~="mn-lt=10px"] { margin-left: 10px }
[data-d~="mn-lt=20px"] { margin-left: 20px }
[data-d~="mn-lt=30px"] { margin-left: 30px }
[data-d~="mn-lt=40px"] { margin-left: 40px }
[data-d~="mn-lt=80px"] { margin-left: 80px }



[data-d~="tn-dn=250ms"] { transition-duration: 250ms }
[data-d~="tn-dn=500ms"] { transition-duration: 500ms }



[data-d~="tm=re(-45deg)"] { transform: rotate(-45deg) }
[data-d~="tm=re(45deg)"] { transform: rotate(45deg) }
[data-d~="tm=teX(-100vw)"] { transform: translateX(-100vw) }

[data-d~="re=180deg"] { rotate: 180deg }

[data-d~="we-se=nowp"] { white-space: nowrap }

[data-d~="te-lt=fd"] { table-layout: fixed }

[data-d~="an-in-ct=ie"] { animation-iteration-count: infinite }

[data-d~="an-dn=2s"] { animation-duration: 2s }
[data-d~="an-dn=20s"] { animation-duration: 20s }
[data-d~="an-dn=50s"] { animation-duration: 50s }

[data-d~="an-ne=_1"] { animation-name: pulse_1__1 }
[data-d~="an-ne=_2"] { animation-name: marquee_1__1 }
[data-d~="an-ne=_3"] { animation-name: scroll_1__1 }
[data-d~="an-ne=_4"] { animation-name: pulse_2__1 }
[data-d~="an-ne=_5"] { animation-name: rotate_1__1 }

[data-d~="an-tg-fn=lr"] { animation-timing-function: linear }

[data-d~="an-py-se=pd"] { animation-play-state: paused }

[data-d~="fx-sk=0"] { flex-shrink: 0 }

[data-d~="wd-bk=al"] { word-break: break-all }

[data-d~="re=vl"] { resize: vertical; } 




[data-d~=":hr=bd-cr=1st"]:hover { background-color: $color1st }

[data-d~=":hr=oe-se=dd"]:hover { outline-style: dashed }
[data-d~=":hr=oe-cr=rd"]:hover { outline-color: red }
[data-d~=":hr=oe-wh=3px"]:hover { outline-width: 3px }

[data-d~=":hr=bd-cr=22"]:hover { background-color: $color22 }
[data-d~=":fs-wn=bd-cr=22"]:focus-within { background-color: $color22 }

[data-d~="::pr=co=we"]::placeholder { color: white }
[data-d~="::pr=co=bk"]::placeholder { color: black }

[data-d~="::-wt-sr=oy=0"]::-webkit-scrollbar { opacity: 0 }
[data-d~="::-wt-sr=dy=ne"]::-webkit-scrollbar { display: none }

[data-d~=":hr=tt-dn=ue"]:hover { text-decoration: underline }

[data-d~=":hr->tt-dn"] {
    &:hover {
        [data-d~=":hr->tt-dn=ue"] { text-decoration: underline }
    }
}

[data-d~=":fs-wn->tt-dn"] {
    &:focus-within {
        [data-d~=":fs-wn->tt-dn=ue"] { text-decoration: underline }
    }
}

[data-d~=":fs-wn->wh"] {
    &:focus-within {
        [data-d~=":fs-wn->wh=100%"] { width: 100% }
    }
}

[data-d~=":fs-wn->co"] {
    &:focus-within {
        [data-d~=":fs-wn->co=we"] { color: var(--color-we) }
        [data-d~=":fs-wn->co=bk"] { color: var(--color-bk) }
    }
}


[data-d~=":hr->wh"] {
    &:hover {
        [data-d~=":hr->wh=100%"] { width: 100% }
    }
}

[data-d~=":hr->co"] {
    &:hover {
        [data-d~=":hr->co=we"] { color: white }
        [data-d~=":hr->co=bk"] { color: black }
        [data-d~=":hr->co=1st"] { color: $color1st }
    }
}

[data-d~=":ft-cd->mn-tp=il"] {
    &:first-child { margin-top: initial }
}

[data-d~=":lt-cd->dy"] {
    &:last-child {
        [data-d~=":lt-cd->dy=ne"] { display: none }
    }
}

[data-d~=":ft-cd->bd-cr"] {
    &:first-child {
        [data-d~=":ft-cd->bd-cr=1st"] { background-color: $color1st }
    }
}

[data-d~=":ft-cd->co"] {
    &:first-child {
        [data-d~=":ft-cd->co=we"] { color: white }
    }
}








[data-d~="%1__1"] {
    &:last-child {
        [data-d~="%1__2"] { display: none }
    }
}





[data-d*="|"] {
	@include g("36");
	@include d("pn=re");

	> *:first-child {
		@include d("pn=ae");
		@include d("tp=0");
		@include d("rt=0");
		@include d("bm=0");
		@include d("lt=0");
		@include d("ow=hn");
	}

	&[data-d~="|60"] { @include d("pg-tp=60%") }
}

[data-p~="text_1__1"] {
	@include d("ft-se=11rem");
	@include d("lr-sg=1px");
	@include d("co=bk");
	@include d("le-ht=1.2");
	@include d("tt-tm=ue");
}

[data-p~="text_2__1"] {
	@include d("ft-se=11rem");
	@include d("co=bk");
	@include d("le-ht=1.2");
	@include d("tt-tm=ue");
	@include d("ft-wt=nl");
}



[data-p~="ul_1__1"] {
    ul {
        @include d("lt-se-te=dc");
        @include d("pg-lt=20px");

	    &[data-d~="lt-se-te=ne"] { @include d("lt-se-te=ne") }
	    &[data-d~="pg-lt=il"] { @include d("pg-lt=il") }
    }
    ol {
        @include d("lt-se-te=dl");
        @include d("pg-lt=20px");

	    &[data-d~="lt-se-te=ne"] { @include d("lt-se-te=ne") }
	    &[data-d~="pg-lt=il"] { @include d("pg-lt=il") }
    }
}

[data-p~="table_1__1"] {
	@include g("36");
    @include d("br-ce=ce");

    thead {
		@include g("36");

        tr {
            th {
                @include d("bd-cr=3rd");
                @include d("br-cr=4th");
                @include d("br-se=sd");
                @include d("br-wh=1px");

                > * {
                    @include d("pg=5px");
                }
                &:first-child {
                    > * {
                        @include a("_");
                        @include a(".x.");
                        @include a(".y.");

                        @include d("mn-wh=35px");
                        @include d("mn-ht=50px");
                    }
                }
                &:last-child {
                    > * {
                        @include d("pg=5px20px");
                    }
                }
            }
        }
    }
    tbody {
		@include g("36");

        tr {
            th {
                @include d("bd-cr=3rd");
                @include d("br-cr=4th");
                @include d("br-se=sd");
                @include d("br-wh=1px");

                &:first-child {
                    > * {
                        @include a("_");
                        @include a(".x.");
                        @include a(".y.");

                        @include d("mn-wh=35px");
                        @include d("mn-ht=35px");
                    }
                }
            }
			td {
				@include d("pg=5px20px");
                @include d("br-cr=4th");
                @include d("br-se=sd");
                @include d("br-wh=1px");
                @include d("tt-an=cr");
			}
		}
    }
    tfoot {
		@include g("36");

        tr {
			th {
                @include d("br-cr=4th");
                @include d("br-se=sd");
                @include d("br-wh=1px");

                > * {
                    @include d("pg=5px20px");
                }
			}
            td {
                @include d("br-cr=4th");
                @include d("br-se=sd");
                @include d("br-wh=1px");
                @include d("tt-an=cr");

                &:first-child {
                    > * {
                        @include a("_");
                        @include a(".x.");
                        @include a(".y.");

                        @include d("mn-wh=35px");
                        @include d("mn-ht=35px");
                    }
                }
			}
		}
    }
}

[data-p~="table_2__1"] {
	@include g("36");
    @include d("br-ce=ce");

    th {
        @include d("bd-cr=3rd");
        @include d("br-cr=4th");
        @include d("br-se=sd");
        @include d("br-wh=1px");

        > * {
            @include a("_");
            @include a(".x.");
            @include a(".y.");

            @include d("mn-ht=35px");
            @include d("pg=5px20px");
        }
    }
    td {
        @include d("br-cr=4th");
        @include d("br-se=sd");
        @include d("br-wh=1px");

        > * {
            @include a("_");
            @include a(".x.");
            @include a(".y.");

            @include d("mn-ht=35px");
            @include d("pg=5px20px");
        }
    }
}

[data-p~="table_3__1"] {
	@include g("36");
    border-collapse: collapse;

    a {
        color: #b50315;

        &:hover {
            text-decoration: underline;
        }
    }

    thead {
		@include g("36");

        tr {
		    @include g("36");

            th {
                text-align: left;
                padding: 10px;
            }
            td {
                padding: 10px;
            }
        }
    }
    tbody {
		@include g("36");

        tr {
		    @include g("36");
            border-top-style: solid;
            border-color: #dddddd;
            border-width: 1px;

            th {
                text-align: left;
                padding: 10px;
            }
			td {
                padding: 10px;
			}
		}
    }
    tfoot {
		@include g("36");

        tr {
		    @include g("36");

			th {
                text-align: left;
                padding: 10px;
			}
            td {
                padding: 10px;
			}
		}
    }
}